.hero {
    position: relative;
    height: 100vh;
    background-image: url('../media/Images/Cover-sky.jpg');
    background-size: cover;
    background-position: center;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
            ellipse at center,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(0, 0, 0, 0.3) 30%,
            rgba(0, 0, 0, 0.2) 60%,
            rgba(0, 0, 0, 0.1) 80%,
            rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
}


.hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    text-align: center;
    color: #fff;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.hero-button {
    display: inline-block;
    border: 2px solid coral;
    border-radius: 20px;
    color: coral;
    font-weight: bold;
    background: transparent;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
}

.hero-button:hover {
    background-color: coral;
    color: white;
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2rem;
    }

    .hero-subtitle {
        font-size: 1.25rem;
    }

    .hero-buttons {
        display: unset;
    }

    .hero-button {
        font-size: 0.9rem;
    }
}
