/*start of nav bar*/
.nav-bar {
    width: 100%;
    height: 48px;
    background-color: #2b2b2b;
    position: fixed;
    display: flex;
    z-index: 100;
    top: 0;
}

.nav-bar > img {
    margin-left: 5rem;
    margin-top: 0.5rem;
    border-radius: 5px;
}

.menu-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-header > ul {
    place-items: center;
    display: flex;
    list-style-type: none;
    color: aliceblue;

}

.menu-header_item {
    padding-left: 3rem;
    transition-duration: 1s;
}

a {
    color: white;
    text-decoration: none;
}

.menu-header_item:hover {
    color: coral;
    text-shadow: 5px 5px 10px rgba(255, 170, 80, 0.2), -5px -5px 10px rgba(255, 170, 80, 0.2);
    transition-duration: 0.8s;
    cursor: pointer;
}
