.tasting-note {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.tasting-note__row {
    display: flex;
    align-items: center;
    padding: 8px;
}

.tasting-note__row:nth-child(even) {
    background-color: #e3f2fd; /* Light blue shade */
}

.tasting-note__row:nth-child(odd) {
    background-color: #fff; /* White */
}

.tasting-note__label {
    font-weight: bold;
    margin-right: 10px;
}

.tasting-note__value {
    flex-grow: 1;
}

.tasting-note__heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
