/* theme colours */
:root {
    --text-header: #000;
    --text-paragraph: #000;
    --bg-primary: #fff;
    --bg-secondary: #fff;
    --button-primary: #4caf50;
    --button-primary-hover: #45a049 ;
}

.overflow-hidden {
    overflow: hidden;
}
/* Font styles */
body {
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
}

/* Margin and padding */
body {
    margin: 0;
    padding: 0;
}

/* Add other Bulma styles here */

