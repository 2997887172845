.login-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.login-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.login-form-input-label {
    color: #000;
    text-align: start;
}

.login-form-submit {
    width: 100%;
    padding: 10px;
    background-color: var(--button-primary);
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.login-form-submit:hover {
    background-color: var(--button-primary-hover);
}

.login-form-submit-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-form-submit:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: #cc0f35;
}

/* Media queries for mobile devices */
@media (max-width: 480px) {
    .login-form-container {
        max-width: 300px;
        width: 100%;
    }
}
