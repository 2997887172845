.page {
    display: flex;
}

.leftcol {
    flex: 1;
    padding-right: 20px;
    padding-top: 20px;
}

.rightcol {
    flex: 1;
    padding-left: 20px;
    padding-top: 20px;
}

.favourite {
    position: absolute;
    bottom: 0;
    right: 0;
    fill: goldenrod;
}

@media (max-width: 992px) {
    .page {
        flex-direction: column;
    }

    .leftcol,
    .rightcol {
        flex: none;
        padding: 20px 0 0;
        margin-bottom: 20px;
    }
}

.imageModal {
    width: 50%;
}

.name {
    font-weight: bold;
    margin-bottom: 10px;
}

.inline {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 10px;
}

.grape {
    margin-right: 10px;
}

.year {
    font-weight: bold;
}

.description {
    margin-top: 20px;
}

.modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}
