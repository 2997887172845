/* Styles for the form container */
.form-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

/* Styles for form fields */
.form-field {
    margin-bottom: 10px;
}

.form-field label {
    display: block;
    font-weight: bold;
}

.form-field input,
.form-field textarea,
.form-field select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-field input[type="file"] {
    display: none;
}

.form-field .image-upload {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.form-field .image-upload label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #999;
}

.form-field .image-upload img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.form-field .image-upload-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Styles for the submit button */
.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: var(--button-primary);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: var(--button-primary-hover);
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
