.not-found-container {
    text-align: center;
    margin-top: 100px;
}

h1 {
    font-size: 36px;
    font-weight: bold;
}

p {
    font-size: 18px;
    color: #888;
}
