.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.8;
}

.modal-container {
    position: fixed;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    padding: 1rem;
    margin-top: 1rem;
    background-color: white;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.modal-content {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

.modal-content:first-child {
    margin-top: 3%;
}
