.hero-image {
    background-image: url("http://localhost:5051/images/02062023172942.jpg");

    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;

    padding: 0;
    margin-left: -3%;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    padding: 30px;
    transform: translate(-50%, -50%);
    color: #2b2b2b;
    text-shadow: 0 1px 0 rgba(255,255,255,.3), 0 -1px 0 rgba(0,0,0,.7);
}
.hero-text > h1 {
    font-size: 72pt;
    letter-spacing: 0.6rem;
}
.hero-text > h2 {
    font-size: 40pt;
    letter-spacing: 2.5rem;
    margin-top: -10%;
}
/*end of hero*/

/*about columns*/
.columns {
    display: flex;
    width: 80%;
    margin: auto;
}

.column {
    flex: auto;
    width: 33%;
    padding: 0.2rem;
    margin: 0.2rem 0.4rem;
    text-align: justify;
}

.column > img {
    margin: 25% auto auto;
    padding: 0.5rem;
}

/* Stack columns vertically on small screens */
@media (max-width: 850px) {
    .columns {
        display: block;
        width: 100%;
        text-align: center;
    }

    .column {
        width: 100%;
        margin: 1rem 0;
    }

    .column > img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        padding: 0.5rem;
    }
}

/*end of about columns*/
